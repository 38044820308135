body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sistemCard {
  transition: all 300ms;
  transform: scale(1);
}

.sistemCard:hover {
  transition: all 300ms;
  transform: scale(1.1);
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #23265d;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

.vender .css-fevq5a-MuiFormLabel-root-MuiInputLabel-root {
  padding-top: 5px;
}

.vender .css-1q60rmi-MuiAutocomplete-endAdornment {
  top: calc(50% - 18px);
}

.btnIngresarConPass {
  color: white;
}

.btnIngresarConPass:hover {
  background-color: white;
  color: #23265d;
}

.tersuave .st0,
.colorin .st0,
.industria .st0,
.st0 {
  fill: black;
}

.tersuave .st1,
.colorin .st1,
.industria .st1,
.st1 {
  fill: none;
  stroke: black;
  stroke-width: 3;
  stroke-miterlimit: 10;
}

.svg-elem-3 {
  -webkit-animation: animate-svg-stroke-3 3s linear 0.24s both;
  animation: animate-svg-stroke-3 3s linear 0.24s both;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.d-flex{
  display: flex;

}
.justify-content-center{
  justify-content: center;
}

@-webkit-keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 2452.71435546875px;
    stroke-dasharray: 2452.71435546875px;
  }
  20% {
    stroke-dashoffset: 2452.71435546875px;
    stroke-dasharray: 2452.71435546875px;
  }
  80% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2452.71435546875px;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2452.71435546875px;
  }
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 2452.71435546875px;
    stroke-dasharray: 2452.71435546875px;
  }
  20% {
    stroke-dashoffset: 2452.71435546875px;
    stroke-dasharray: 2452.71435546875px;
  }
  80% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2452.71435546875px;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2452.71435546875px;
  }
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
